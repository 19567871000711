import ankh from "../assets/ankh.svg?react";
import bast from "../assets/bast.svg?react";
import ra from "../assets/ra.svg?react";
import scarab from "../assets/scarab.svg?react";

export default {
  ankh,
  ra,
  bast,
  scarab,
};
