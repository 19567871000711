import { FortuneWheelButton } from "@features/fortune-wheel-button";
import { GameSearch } from "@features/game-search";
import { TakeBonusButton } from "@features/take-bonus-button";
import { WalletIntegrationWarningModal } from "@features/wallet-integration-warning-popup";
import { FortuneWheel } from "@lobby/core/entities";
import { useMobile } from "@lobby/core/shared";
import { FloatGroup } from "@shared/ui";
import { BannerPanelDesktop, BannerPanelMobile } from "@widget/banner-panel";
import { CategoryList } from "@widget/category-list";
import { Footer } from "@widget/footer";
import { GameLists } from "@widget/game-lists";
import { Header } from "@widget/header";
import { Jackpots } from "@widget/jackpots";
import { LogInModal } from "@widget/log-in-modal";
import { PasswordForgotModal } from "@widget/password-forgot-modal";
import { ProviderList, ProvidersModal } from "@widget/provider-list";
import { RegisterModal } from "@widget/register-modal";
import { SupportChat } from "@widget/support-chat";
import "./styles.css";

export function HomePage() {
  const isMobile = useMobile();
  const isDesktop = !isMobile;
  const isFortuneWheelAvailable = FortuneWheel.useIsWheelAvailable();

  return (
    <>
      <Header />
      <div className="absolute h-[12.5rem] w-full bg-[linear-gradient(180deg,#150221,#2D0656_5%,#180728_50%,#180023)]" />
      <main className="home-page__main relative space-y-3 p-2.5 lg:mx-auto lg:max-w-screen-3xl lg:space-y-5 lg:p-5">
        <>
          <LogInModal />
          <RegisterModal />
          <PasswordForgotModal />
          <ProvidersModal />
          <WalletIntegrationWarningModal />
        </>

        {isDesktop ? <BannerPanelDesktop /> : <BannerPanelMobile />}

        <Jackpots />

        {isDesktop && (
          <div
            className="grid grid-cols-[18.5rem_minmax(0,auto)] gap-5"
            id="navigate-category-anchor"
          >
            <CategoryList />
            <div className="grow space-y-5">
              <ProviderList />
              <GameLists />
            </div>
          </div>
        )}
        {isMobile && (
          <>
            <section>
              <GameSearch className="[&_input]:bg-cod-gray" />
            </section>

            {isFortuneWheelAvailable && <FortuneWheelButton.Mobile />}

            <ProviderList />
            <CategoryList />
            <GameLists id="navigate-category-anchor" />
          </>
        )}
      </main>
      <Footer />

      <FloatGroup>
        <SupportChat />
        <TakeBonusButton className="mt-5 lg:mt-10" />
      </FloatGroup>
    </>
  );
}
