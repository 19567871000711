import { LanguageSelector } from "@features/language-selector";
import { RegisterForm } from "@features/register-form";
import { Lobby } from "@lobby/core/entities";
import { useAuth, useModal } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button, Logo } from "@shared/ui";
import { Modal } from "@shared/ui/modal";
import { useNavigate } from "@tanstack/react-router";
import { useEffect } from "react";

export function RegisterModal({ isStatic = false }: { isStatic?: boolean }) {
  const { isOpen, close } = useModal("register");

  return (
    <Modal open={isOpen}>
      <Modal.Overlay onClick={isStatic ? undefined : close} />
      <RegisterModalComponent />
    </Modal>
  );
}

function RegisterModalComponent() {
  const { open: openLogInModal } = useModal("log-in");
  const { $t } = useTranslate();
  const navigate = useNavigate();

  const { isAuth } = useAuth();
  const { data: lobbyData } = Lobby.useLobby();

  const isRegistrationEnabled = lobbyData?.registration.enabled ?? false;

  useEffect(() => {
    if (isAuth || !isRegistrationEnabled) {
      navigate({
        to: "/",
        replace: true,
      });
    }
  }, [isAuth, isRegistrationEnabled, navigate]);

  return (
    <Modal.Panel className="mobile-only:min-h-full w-full lg:top-17 lg:w-[25rem]">
      <Logo className="mx-auto h-20" />
      <div className="mt-5 flex-c-sb gap-5">
        <div className="font-bold text-14 uppercase lg:text-16">
          {$t({ defaultMessage: "Registration" })}
        </div>
        <Button onClick={openLogInModal}>{$t({ defaultMessage: "sign in" })}</Button>
      </div>

      <div className="mt-7 mobile-only:mb-10">
        <RegisterForm />
      </div>

      <div className="mt-auto flex items-center justify-between">
        <LanguageSelector orientation="top" />
      </div>
    </Modal.Panel>
  );
}
