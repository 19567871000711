import type { TJackpotsType } from "@lobby/core/entities";
import Icons from "./icons";

export function getJackpotIcon(type: TJackpotsType) {
  switch (type) {
    case "mini":
      return Icons.scarab;
    case "major":
      return Icons.ankh;
    case "grand":
      return Icons.ra;
    case "ultimate":
      return Icons.bast;
  }
}
