import brickImg from "@app/assets/brick.avif";
import { LanguageSelector } from "@features/language-selector";
import { MobileMenuButton } from "@features/mobile-menu-button";
import { PlayerInfo } from "@features/player";
import { useAuth, useModal } from "@lobby/core/shared";
import { useTranslate } from "@lobby/ocb-intl";
import { Button } from "@shared/ui/button";
import { Logo } from "@shared/ui/logo";
import { Link } from "@tanstack/react-router";
import { useHeaderCategories } from "../lib";
import { NavigationList } from "./components";

export function Header() {
  const { $t } = useTranslate();
  const { isAuth } = useAuth();
  const { open: openLogInModal } = useModal("log-in");
  const headerCategories = useHeaderCategories();

  return (
    <header>
      <div className="lg:h-24">
        <div className="mx-auto flex h-full mobile-only:flex-col justify-between lg:max-w-screen-3xl lg:px-5">
          <nav className="header__left mobile-only:order-3">
            <div className="mobile-only:hidden flex-shrink-0">
              <Link to="/">
                <Logo className="h-20" />
              </Link>
            </div>
            <NavigationList items={headerCategories} />
          </nav>
          <div className="header__right mobile-only:order-1">
            <div className="flex items-center gap-4 lg:hidden">
              <MobileMenuButton />
              <Link to="/">
                <Logo className="h-20" />
              </Link>
            </div>
            <LanguageSelector className="mobile-only:hidden" />
            <div className="flex h-full items-center gap-5 lg:contents">
              {isAuth ? (
                <PlayerInfo />
              ) : (
                <Button onClick={openLogInModal}>{$t({ defaultMessage: "sign in" })}</Button>
              )}
            </div>
          </div>
          <div
            style={{ background: `url(${brickImg}) top left / contain` }}
            className="order-2 h-2.5 lg:hidden"
          />
        </div>
      </div>

      <div
        style={{ background: `url(${brickImg}) top left / contain` }}
        className="mobile-only:hidden h-2.5"
      />
    </header>
  );
}
