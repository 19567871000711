import bonusWinImg from "@app/assets/congrats-modal_box.avif";
import jackpotsWinDefaultImg from "@app/assets/congrats-modal_crown.avif";
import jackpotsWinGrandImg from "@app/assets/congrats-modal_crown_grand.avif";
import jackpotsWinMajorImg from "@app/assets/congrats-modal_crown_major.avif";
import jackpotsWinMiniImg from "@app/assets/congrats-modal_crown_mini.avif";
import jackpotsWinUltimateImg from "@app/assets/congrats-modal_crown_ultimate.avif";

import fortuneWheelWinImg from "@app/assets/congrats-modal_wheel.avif";
import type { TJackpotsType } from "@lobby/core/entities";
import type { CongratsModalType } from "@shared/lib";

const jackpotsType: Record<TJackpotsType, string> = {
  mini: jackpotsWinMiniImg,
  major: jackpotsWinMajorImg,
  grand: jackpotsWinGrandImg,
  ultimate: jackpotsWinUltimateImg,
};

export function getCongratsPrizeImage(
  type: CongratsModalType,
  jackpotType?: TJackpotsType,
): string {
  switch (type) {
    case "bonus":
      return bonusWinImg;
    case "jackpot":
      return jackpotType ? jackpotsType[jackpotType] : jackpotsWinDefaultImg;
    case "fortune-wheel":
      return fortuneWheelWinImg;
  }
}
